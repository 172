// import { reject, resolve } from 'core-js/fn/promise'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    type: '1',//审核状态判断
    haikangShow: false,//海康订单示例图显示标识
  },
  mutations: {
  },
  getters: {
  },
  actions: {
  },
  modules: {
  }
})
