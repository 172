import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'learningVideo',
    component: () => import('../views/learningVideo.vue')
  },
  {
    path: '/learningVideo',
    name: 'learningVideo',
    title:'学习视频',
    component: () => import('../views/learningVideo.vue')
  },
  {
    path: '/videoPlayer',
    name: 'videoPlayer',
    title:'视频播放',
    component: () => import('../views/videoPlayer.vue')
  },
  {
    path: '/feedbackDetails',
    name: 'feedbackDetails',
    title:'反馈详情',
    component: () => import('../views/feedbackDetails.vue')
  },
  {
    path: '/feedbackHistory',
    name: 'feedbackHistory',
    title:'反馈历史',
    component: () => import('../views/feedbackHistory.vue')
  },
  {
    path: '/orderPending',
    name: 'orderPending',
    title:'订单挂起APP',
    component: () => import('../views/orderPending.vue')
  },
  {
    path: '/orderPendingWX',
    name: 'orderPendingWX',
    title:'订单挂起微信',
    component: () => import('../views/orderPendingWX.vue')
  },
  {
    path: '/informationFeedback',
    name: 'informationFeedback',
    title:'订单信息反馈APP',
    component: () => import('../views/informationFeedback.vue')
  },
  {
    path: '/informationFeedbackWX',
    name: 'informationFeedbackWX',
    title:'订单信息反馈微信',
    component: () => import('../views/informationFeedbackWX.vue')
  },
  {
    path: '/cancelPending',
    name: 'cancelPending',
    title:'订单取消挂起APP',
    component: () => import('../views/cancelPending.vue')
  },
  {
    path: '/cancelPendingWX',
    name: 'cancelPendingWX',
    title:'订单取消挂起微信',
    component: () => import('../views/cancelPendingWX.vue')
  },
  {
    path: '/proofAPPService',
    name: 'proofAPPService',
    title:'服务凭证上传APP',
    component: () => import('../views/proofAPPService.vue')
  },
  {
    path: '/proofWXService',
    name: 'proofWXService',
    title:'服务凭证上传微信',
    component: () => import('../views/proofWXService.vue')
  },
  {
    path: '/proofService',
    name: 'proofService',
    title:'服务凭证上传',
    component: () => import('../views/proofService.vue')
  },
  {
    path: '/nailingHtml',
    name: 'nailingHtml',
    title:'钉钉任务操作',
    component: () => import('../views/freeHtml/nailingHtml.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})
export default router
